@import "../Varible";
.datashow {
  display: flex;
  width: 49%;
  position: relative;
  flex-direction: column;
  height: 100%;
  border: 2px solid $text--color-primary;
  margin-bottom: 1rem;
  @media (max-width: 600px) {
    width: 80%;
    margin: 2rem;
  }
  &__item {
    border-top: 2px solid $text--color-primary;
    padding: 1rem 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    &--text {
      font-size: 2rem;
      color: $text--color-primary;
      font-family: "Della Respira", serif;
    }
    &:hover {
      color: $color--primary;
      background-color: $text--color-primary;
      & * {
        color: $color--primary;
        fill: $color--primary;
      }
    }
    &_svg {
      height: 40px;
      width: 40px;
      margin-right: 0.8rem;
      path {
        fill: $text--color-primary;
      }
    }
  }
  &__heading {
    font-family: "Exo", sans-serif;
    padding: 0.8rem;
    font-size: 4rem;
    color: $text--color-primary;
  }
  &__footer {
    color: $text--color-primary;
  }
}
