@import '../Varible';
* {
	scroll-behavior: smooth;
}
.search {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	height: 40rem;
	width: 30vw;

	align-items: center;
	transition: all 0.5s ease;
	border-right: 2px solid $color--Tertiary;
	border-bottom: 2px solid $color--Tertiary;
	@media (max-width: 800px) {
		padding: 2rem 8rem;
		height: 40rem;
		width: 100vw;
	}
	@media (max-width: 340px) {
		padding: 1rem 4rem;
	}
	label {
		width: 100%;
	}
	&__input {
		width: 100%;
		padding: 1rem;
		font-size: 2rem;
		background-color: $color--Tertiary;
		border: none;
		border-radius: 3rem;
		margin: 2rem 0;
		color: $text--color-primary;
		font-family: 'Della Respira', serif;
		transition: all 0.2s ease-in;
		@media (max-width: 340px) {
			font-size: 1.5rem;
			padding: 0.5rem;
		}
		&:active,
		&:focus,
		&:hover {
			outline: none;
			background-color: $color--Pentanary;
			color: $text--color-primary;
		}
		&::placeholder {
			color: $text--color-primary;
		}
	}
}
.result {
	overflow: scroll;
	width: 100%;
	.country {
		display: block;
		cursor: pointer;
		width: 100%;
		background-color: transparent;
		border: 2px solid $text--color-primary;
		color: $text--color-primary;
		padding: 1rem;
		margin-top: 1rem;
		font-size: 1.7rem;
		border-radius: 2rem;
		transition: all 0.2s ease-in;
		font-family: 'Della Respira', serif;
		text-decoration: none;
		&:hover {
			background-color: $text--color-primary;
			color: $color--Tertiary;
		}
		&:active,
		&:focus {
			outline: none;
			background-color: $text--color-primary;
			color: $color--Tertiary;
			border-color: $color--Tertiary;
		}
		@media (max-width: 340px) {
			font-size: 1.5rem;
		}
	}
}
