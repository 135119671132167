@import "../Varible.scss";
.global--btn {
  background-color: transparent;
  border: 4px solid $text--color-primary;
  padding: 1rem;
  margin-bottom: 2rem;
  margin: 1rem;
  height: 5rem;
  max-width: 10rem;
  color: $text--color-primary;
  font-size: 1.5rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Della Respira", serif;
  transition: all 0.3s ease;
  border-radius: 1rem;
  &:hover {
    background-color: $text--color-primary;
    color: #000;
  }
  &:disabled {
    border-color: rgba($text--color-primary, $alpha: 0.2);

    color: rgba($text--color-primary, $alpha: 0.5);
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }
  }
}
.btns {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
